// Include scss
import "../scss/index.scss";

// Images
import "./images.js";


/* 
    Libs 
*/
import "imask";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import Swiper, {Navigation, HashNavigation} from 'swiper';
import '../../node_modules/swiper/swiper.scss';

// Lazyload
import LazyLoad from "vanilla-lazyload";
var myLazyLoad = new LazyLoad();
myLazyLoad.update();

import ScrollMagic from 'scrollmagic';
import { gsap } from "gsap";
import { TweenMax, TimelineMax } from "gsap"; // What to import from gsap
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', function () {

    Fancybox.bind('[data-fancybox]', {
        // Custom options for all galleries
    }); 
    
    for(let scrl of this.document.querySelectorAll('.js-anim-opacity-on-viewport')) {
        gsap.to(scrl, { 
            duration: 1,
            opacity: 1,
            scrollTrigger: scrl,
        })
    }
});


// Swiper

const swiper = new Swiper('.js-test-slider', {
    modules: [
        Navigation,
        HashNavigation
    ],
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: true,
    slidesPerView: "auto",
    //hashNavigation: true,
    /*pagination: {
        //el: "#js-test-slider-pagination",
        clickable: true,
    },
    */
    navigation: {
        nextEl: document.querySelector(".swiper-button-next"),
        prevEl: document.querySelector(".swiper-button-prev"),
    },

    
});

/*
// MagicScroll + GSAP
import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from "gsap"; // What to import from gsap
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
*/

// Smooth scroll
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        let elem = document.querySelector(this.getAttribute('href'));

        if(!elem) {
            return;
        }

        elem.scrollIntoView({
            behavior: 'smooth'
        });
    });
});