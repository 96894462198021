// Include scss
import "../scss/index.scss";

// Images
import "./images.js";


/* 
    Libs 
*/
import "imask";

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import Swiper, { Navigation, Pagination } from 'swiper';
import '../../node_modules/swiper/swiper.scss';

// Lazyload
import LazyLoad from "vanilla-lazyload";
var myLazyLoad = new LazyLoad();
myLazyLoad.update();

import ScrollMagic from 'scrollmagic';
import { gsap } from "gsap";
import { TweenMax, TimelineMax } from "gsap"; // What to import from gsap
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', function () {

    Fancybox.bind('[data-fancybox]', {
        // Custom options for all galleries
    }); 
    
    for(let scrl of this.document.querySelectorAll('.js-anim-opacity-on-viewport')) {
        gsap.to(scrl, { 
            duration: 1,
            opacity: 1,
            scrollTrigger: scrl,
        })
    }
});

/*
// Swiper
import Swiper from 'swiper';
import '../../node_modules/swiper/swiper.scss';

const swiper = new Swiper('#js-test-slider', {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
        el: "#js-test-slider-pagination",
        clickable: true,
    }
});

// MagicScroll + GSAP
import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax } from "gsap"; // What to import from gsap
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
*/
